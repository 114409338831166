import React from "react";
import moment from "moment";
import { applyCurrencyMask } from "../../../../../utils/CurrencyMask";
import { Button } from "../../../../../components/ui/Buttons";
import MoreIcon from "../../../../../components/Icon/MoreIcon";
import PopperCustomMenu from "../../../../../components/Popper/PopperCustomMenu";
import { menuOpcoesPopper } from "./menuOpcoesPopper";
import Situacao from "../../../../../components/Agenda/Situacao/Situacao";

export const getColumns = ({ handleClickVisualizar, functionsPopper, popperRef ,whitelabel}) => {
  return [
    {
      Header: "Data",
      field: "dataHoraLancamento",
      getValue: (data) => moment(data.dataHoraLancamento).format("DD/MM/YYYY"),
      props: { center: "true" },
    },
    {
      Header: "Orçamento",
      getValue: (data) => data.nome,
      props: { center: "true" },
    },
    {
      Header: "Profissional",
      getValue: (data) => data.profissionalSaude?.nome || null,
      props: { center: "true" },
    },
    {
      Header: "valor",
      getValue: (data) => applyCurrencyMask(data?.valorTotalLiquido),
      props: { center: "true" },
    },
    {
      Header: "Status",
      getValue: (data) => {
        const { faturado, status } = data;

        return data.status === "PENDENTE" ? (
          <Button onClick={(e) => handleClickVisualizar(popperRef?.current?.closePopper(e), data)}>
            Visualizar
          </Button>
        ) : (
          <Situacao faturado={faturado} label={status} whitelabel={whitelabel}/>
        );
      },
      props: { center: "true" },
    },
    {
      Header: "",
      getValue: (data) => {
        return (
          <PopperCustomMenu
            ref={popperRef}
            placement={"bottom-end"}
            menuOpcoes={menuOpcoesPopper({
              data,
              whitelabel,
              functions: {
                ...functionsPopper,
                handleFaturar: (e) => functionsPopper.handleFaturar(popperRef?.current?.closePopper(e), data.id),
                handleExcluir: (e) => functionsPopper.handleExcluir(popperRef?.current?.closePopper(e), data),
                handleClickVisualizar: (e) => handleClickVisualizar(popperRef?.current?.closePopper(e), data),
                handleInativar: (e) => functionsPopper.handleInativar(popperRef?.current?.closePopper(e), data),
                closePopper: popperRef?.current?.closePopper,
              },
            })}
            iconButton={<MoreIcon color='#505050' />}
            colorButton={{
              background: '#fff',
            }}
          />
        );
      },
      props: { center: "true" },
    },
  ];
};
