import React, { useEffect, useState } from "react";
import { TextFieldSearch } from "../../../../components/TextField";
import InputDateForm from "../../../../components/Input/InputDateForm";
import HeaderRelatorio from "../../HeaderRelatorio";
import PageTitle from "../../../../components/PageTitle/PageTitle";
import styles from "./RelatoriosProcedimentosPeriodoStyles";
import { CircularProgress, Grid, withStyles } from "@material-ui/core";
import Scroll from "../../../../components/InfiniteScroll/Scroll";
import Table from "../../../../components/Table/Table";
import MensagemListaVazia from "../../../../components/Mensagem/MensagemListaVazia";
import { filtersRelatorioPeriodoDefault, pageableDTODefault } from "../RelatorioProcedimentosAgendados/constants";
import { findAllConvenio, findAllProcedimentos, findAllRelatoriosProcedimentoPeriodo, findAllSujeitoAtencao, relatorioProcedimentosPeriodoCsv, } from "../../../../services/RelatorioService";
import Notification from "../../../../components/Notification";
import moment from "moment";

import { inject } from "mobx-react";
import { findAllProfissionalSaude } from "../../../../services/ProfissionalSaudeService";
import { Button } from "../../../../components/ui/Buttons";
import { columns } from "./constants";
import ArrowDownloadIcon from "../../../../components/Icon/ArrowDownload";
import PrintIcon from "../../../../components/Icon/Print";
import { base64Convert } from "../../../../utils/base64ToCsv";
import ImpressaoHtml from "../../../../components/Impressao/ImpressaoHtml";
import RelatorioProcedimentosPeriodo from "../../../../template/pdf/relatorio/ProcedimentosRealizadosPeriodo";

const RelatoriosProcedimentosRealizadosPeriodo = (
  { classes,
    relatorioStore,
  }) => {

  const [isLoading, setIsLoading] = useState(false)
  const [pageableDTO, setPageableDTO] = useState(pageableDTODefault);
  const [filters, setFilters] = useState(filtersRelatorioPeriodoDefault)
  const [procedimentosRealizados, setProcedimentosRealizados] = useState([]);
  const [last, setLast] = useState(false);
  const [totalElements, setTotalElements] = useState(0);
  const [isPrintMustache, setIsPrintMustache] = useState(false);
  const [
    procedimentosRealizadosPrint,
    setProcedimentosRealizadosPrint,
  ] = useState([]);
  const [notification, setNotification] = useState({
    isOpen: false,
    message: "",
  });

  useEffect(() => {
    loadProcedimentosRealizados({ isClearable: true });
  }, [pageableDTO.sortDir]);

  const showAlertMessage = (notification) => {
    setNotification(notification);

    const timeoutId = setTimeout(() => {
      closeAlertMessage();
      clearTimeout(timeoutId);
    }, 3000);
  };

  const closeAlertMessage = () => {
    const notification = {
      isOpen: false,
      message: "",
    };
    setNotification(notification);
  };


  const {
    profissionalSaude,
    procedimentos,
    convenios,
    sujeitoAtencao,
    dataFim,
    dataInicio,
  } = filters || {};



  const mapId = (array) => {
    if (Array.isArray(array)) {
      const ids = array.map(item => { return item.id })
      return ids
    } else {
      return [array.id]
    }
  }


  const loadProcedimentosRealizados = async (options) => {
    try {
      setIsLoading(true);

      const variables = {
        pageableDto: {
          ...pageableDTO,
          pageNumber: options?.isClearable ? 0 : pageableDTO.pageNumber,
        },
        ...(profissionalSaude?.id && {
          profissionalSaudeId: profissionalSaude.id
        }),
        ...(procedimentos && { procedimentos: mapId(procedimentos) }),
        ...(convenios && { convenios: mapId(convenios) }),
        ...(sujeitoAtencao && { sujeitoAtencaoId: sujeitoAtencao.id }),
        dataInicio: moment(dataInicio).format("YYYY-MM-DD"),
        dataFim: moment(dataFim).format("YYYY-MM-DD"),
      };

      const response = await findAllRelatoriosProcedimentoPeriodo(variables);
      const { content, last, totalElements: elements } = response;
      const page = options?.isClearable ? 0 : pageableDTO.pageNumber;

      setProcedimentosRealizados(
        options?.isClearable
          ? [...content]
          : [...procedimentosRealizados, ...content]
      );

      setPageableDTO({
        ...pageableDTO,
        pageNumber: page + 1,
      });
      setLast(last);
      setTotalElements(elements);
    } catch (error) {
      showAlertMessage({
        isOpen: true,
        variant: "error",
        message: "Erro ao carregar o relatório de procedimentos realizados",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleClickSearch = () => {

    if (dataInicio && dataFim) {
      const isValid = relatorioStore.verificaLimiteTrintaDias(dataInicio, dataFim)
      if (isValid) {
        loadProcedimentosRealizados({ isClearable: true });
      } else {
        showAlertMessage({
          isOpen: true,
          variant: "error",
          message: "O limite de sessenta dias foi excedido!",
        });
      }
    }
  }

  const handleFilters = (value, field) => {
    setFilters({ ...filters, [field]: value });
  };

  const loadProcedimentos = async (search, loadedOptions, { page }) => {
    try {
      const { content, last } = await findAllProcedimentos({
        pageableDTO: {
          pageNumber: page,
          pageSize: 10,
          sortDir: "ASC",
          sortField: "nome",
        },
        nome: search,
        ativo: true,
      });
      return {
        options: content,
        hasMore: !last,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.error(error);
    }
  };

  const loadConvenios = async (search, loadedOptions, { page }) => {
    try {
      const { content, last } = await findAllConvenio({
        searchDTO: {
          pageSize: 10,
          pageNumber: page,
          sortDir: "ASC",
          sortField: "descricao",
          search,
          ativo: true,
        },
      });
      return {
        options: content,
        hasMore: !last,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.error(error);
    }
  };

  const loadProfissionaisSaude = async (search, loadedOptions, { page }) => {
    try {
      const { mostrarApenasProfissionaisAtivo } = relatorioStore.configuracaoUnidade || {};
      const { content, last } = (
        await findAllProfissionalSaude({
          pageNumber: page,
          search,
          ativo: mostrarApenasProfissionaisAtivo,
        })
      ).data.data.findAllProfissionalSaude;
      return {
        options: content,
        hasMore: !last,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.error(error);
    }
  };

  const loadPacientes = async (search, loadedOptions, { page }) => {
    try {
      const { content, last } = (
        await findAllSujeitoAtencao({
          pageable: { ...pageableDTODefault, pageNumber: page, sortField: "nome" },
          searchDto: { search: search, }
        })
      ).data.data.findAllSujeitoAtencao;

      return {
        options: content,
        hasMore: !last,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.error(error);
    }
  };

  const handleDownloadCSV = async () => {
    try {
      const variables = {
        pageableDto: pageableDTODefault,
        ...(profissionalSaude?.id && {
          profissionalSaudeId: profissionalSaude?.id,
        }),
        ...(procedimentos && { procedimentos: mapId(procedimentos) }),
        ...(convenios && { convenios: mapId(convenios) }),
        ...(sujeitoAtencao && { sujeitoAtencaoId: sujeitoAtencao.id }),
        dataInicio: moment(dataInicio).format("YYYY-MM-DD"),
        dataFim: moment(dataFim).format("YYYY-MM-DD"),
      };

      const base64 = await relatorioProcedimentosPeriodoCsv(variables);
      if (base64 === "error") return;
      const blob = base64Convert(base64);
      const url = window.URL.createObjectURL(blob);
      let link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", "RelatorioProcedimentosRealizados.csv");
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      showAlertMessage({
        isOpen: true,
        variant: "error",
        message: "Erro ao baixar o relatório",
      });
    }
  };

  const handlePrint = async () => {
    const isValid = relatorioStore.verificaLimiteTrintaDias(dataInicio, dataFim)
    if (!isValid) {
      showAlertMessage({
        isOpen: true,
        variant: "error",
        message: "O limite de sessenta dias foi excedido!",
      });
    }
    try {

      const variables = {
        pageableDto: {
          ...pageableDTO,
          pageSize: 30,
          pageNumber: 0,
        },
        ...(profissionalSaude?.id && {
          profissionalSaudeId: profissionalSaude?.id,
        }),
        ...(procedimentos && { procedimentos: mapId(procedimentos) }),
        ...(convenios && { convenios: mapId(convenios) }),
        dataInicio: moment(dataInicio).format("YYYY-MM-DD"),
        dataFim: moment(dataFim).format("YYYY-MM-DD"),
        ...({ sujeitoAtencaoId: sujeitoAtencao?.id })
      };


      const response = await findAllRelatoriosProcedimentoPeriodo(variables)

      setProcedimentosRealizadosPrint(response.content);
      setIsPrintMustache(true);

    } catch (e) {
      showAlertMessage({
        isOpen: true,
        variant: "error",
        message: "Erro ao gerar relatório!",
      });
    }
  };

  return (
    <div>
      <PageTitle title="Relatório - Procedimentos realizados no período" />
      <HeaderRelatorio
        title="Procedimentos realizados por período"
        hiddenSearch
        hiddenFilter
        hiddenButtons
        hiddenTotal
      />
      <div className={classes.filtros}>
        <div className={classes.campoFiltro}>
          <span className={classes.tituloFiltros}> Data início: </span>
          <InputDateForm
            iconposition="end"
            openTo="day"
            views={["year", "month"]}
            value={filters.dataInicio || ""}
            onChange={(e) => handleFilters(e, "dataInicio")}
            classes={{
              input: classes.inputData,
            }}
          />
        </div>
        <div className={classes.campoFiltro}>
          <span className={classes.tituloFiltros}> Data Fim: </span>
          <InputDateForm
            iconposition="end"
            openTo="day"
            views={["year", "month"]}
            value={filters.dataFim || ""}
            onChange={(e) => handleFilters(e, "dataFim")}
            classes={{
              input: classes.inputData,
            }}
          />
        </div>
        <div className={classes.campoFiltro}>
          <span className={classes.tituloFiltros}> Procedimento: </span>
          <TextFieldSearch
            placeholder="Selecione"
            classNotched={classes.notchedOutline}
            loadOptions={loadProcedimentos}
            getOptionLabel={(option) => option?.nome}
            getOptionValue={(option) => option?.id}
            value={procedimentos}
            onChange={(e) => handleFilters(e, "procedimentos")}
            withPaginate
            debounceTimeout={300}
            additional={{
              page: 0,
            }}
            menuPosition="fixed"
          />
        </div>
        <div className={classes.campoFiltro}>
          <span className={classes.tituloFiltros}> Convênio: </span>
          <TextFieldSearch
            placeholder="Selecione"
            classNotched={classes.notchedOutline}
            loadOptions={loadConvenios}
            getOptionLabel={(option) => option?.descricao}
            getOptionValue={(option) => option?.id}
            value={convenios}
            onChange={(e) => handleFilters(e, "convenios")}
            withPaginate
            debounceTimeout={300}
            additional={{
              page: 0,
            }}
            menuPosition="fixed"
          />
        </div>
        <div className={classes.campoFiltro}>
          <span className={classes.tituloFiltros}> Profissional: </span>
          <TextFieldSearch
            placeholder="Selecione"
            classNotched={classes.notchedOutline}
            loadOptions={loadProfissionaisSaude}
            getOptionLabel={(option) => option?.nome}
            getOptionValue={(option) => option?.id}
            value={profissionalSaude}
            onChange={(e) => handleFilters(e, "profissionalSaude")}
            withPaginate
            debounceTimeout={300}
            additional={{
              page: 0,
            }}
            menuPosition="fixed"
          />
        </div>
        <div className={classes.campoFiltro}>
          <span className={classes.tituloFiltros}> Paciente: </span>
          <TextFieldSearch
            placeholder="Selecione"
            classNotched={classes.notchedOutline}
            loadOptions={loadPacientes}
            getOptionLabel={(option) => option?.nome}
            getOptionValue={(option) => option?.id}
            value={sujeitoAtencao}
            onChange={(e) => handleFilters(e, "sujeitoAtencao")}
            withPaginate
            debounceTimeout={300}
            additional={{
              page: 0,
            }}
            menuPosition="fixed"
          />
        </div>
        <div>
          <Button
            shape='pill'
            bgColor='#707C97'
            onClick={handleClickSearch}
          >
            Filtrar
          </Button>
        </div>

      </div>
      <div className={classes.tableContainer}>
        <Scroll
          loadMore={loadProcedimentosRealizados}
          hasMore={!last}
          pageStart={0}
          initialLoad={false}
        >
          <Table
            dados={procedimentosRealizados}
            columns={columns}
            comOrdenacao
            ordenarTabela={pageableDTO}
          />
          {procedimentosRealizados.length === 0 && !isLoading && (
            <MensagemListaVazia />
          )}
          {isLoading && (
            <Grid
              container
              justify="center"
              alignItems="center"
              style={{ marginTop: 20, width: "100%", height: "100%", display: "flex", justifyContent: "center" }}
            >
              <CircularProgress size={30} />
            </Grid>
          )}
        </Scroll>

        <div className={classes.buttonsDownloadPrint}>
          <Button
            id="exportCsv"
            shape="circle"
            bgColor="#F9BE73"
            onClick={handleDownloadCSV}
          >
            <ArrowDownloadIcon />
          </Button>
          <Button
            shape="circle"
            bgColor="#F9BE73"
            onClick={handlePrint}
          >
            <PrintIcon />
          </Button>
        </div>

        {isPrintMustache && (
          <ImpressaoHtml
            isPrintMustache={isPrintMustache}
            handlePrintMustache={() => setIsPrintMustache(false)}
            htmlStringComponent={
              <RelatorioProcedimentosPeriodo
                dadosRelatorio={procedimentosRealizadosPrint || []}
                filters={filters}
              />
            }
          />
        )}

        <Notification
          close={closeAlertMessage}
          reset={closeAlertMessage}
          isOpen={notification.isOpen}
          variant={"error"}
          message={notification.message}
        />

      </div>


    </div>)
}


const stores = ["unidadeStore", "relatorioStore"];
const RelatorioProcedimentosRealizadosPeriodoWithStyles = withStyles(styles)(
  RelatoriosProcedimentosRealizadosPeriodo
);
export default inject(...stores)(RelatorioProcedimentosRealizadosPeriodoWithStyles);