import { action, observable } from "mobx";

import BaseStore from "./Base.store";
import {
  findAllProcedimentos,
  findOrcamentoOrtopedicoById,
  findProfissionalSaudeByUnidadeComAgendaPage,
  getLinkFaturamentoOrcamento,
  getProcedimentoOdontologico,
} from "../services/OrcamentoService";
import { findAllProcedimentoOdontologicoSemOrcamento, findOrcamentoById } from "../services/OdontogramaService";
import {
  tiposDescontoModalOrcamento,
  tiposModalOrcamento,
} from "../pages/Atendimento/Tabs/Odontograma/Modal/Orcamento/Constatnts";
import string from "../utils/string";

export const statusOptions = [
  {
    label: "Aprovado",
    value: "APROVADO",
  },
  {
    label: "Cancelado",
    value: "CANCELADO",
  },
  {
    label: "Pendente",
    value: "PENDENTE",
  },
  {
    label: "Inativo",
    value: "INATIVO",
  },
];

export const tipoOptions = [
  {
    label: "Odontológico",
    value: "ODONTOLOGICO",
  },
  {
    label: "Procedimento",
    value: "PROCEDIMENTO",
  },
];

export const tipoDesconto = {
  "PERCENTIL": 0, 
  "VALOR": 1,
}

export const defaultErrors = {
  nome: false,
  procedimentos: false,
  tipoPagamento: false,
  valor: false,
};

export const orcamentoDefault = {
  convenio: null,
  dataVencimento: "",
  desconto: {
    valorDesconto: "",
    tipoDesconto: "",
  },
  id: null,
  nome: "",
  observacao: "",
  orcamentoProcedimentos: [],
  profissionalSaude: null,
  status: "PENDENTE",
  tipoPagamento: {
    label: "À vista",
    value: "A_VISTA",
  },
  totalParcelas: "",
  valor: "",
  valorEntrada: "",
  tipo: "ODONTOLOGICO",
};

const tiposRevestimento=[
  {
    label:'EVA',
    value:'REVESTIMENTO_EVA'
  },
  {
    label:'Tecido',
    value:'REVESTIMENTO_TECIDO'
  }
]


export default class Orcamento extends BaseStore {
  odontogramaStore = null;
  sujeitoAtencaoStore = null;

  @observable procedimentos = [];
  @observable errors = defaultErrors;
  @observable orcamento = orcamentoDefault;
  @observable tipoRevestimento = { label:'EVA',value:'REVESTIMENTO_EVA'}
  @observable tiposDeRevestimento = tiposRevestimento

  constructor(rootStore) {
    super();
    this.odontogramaStore = rootStore.odontogramaStore;
    this.sujeitoAtencaoStore = rootStore.sujeitoAtencaoStore;
  }

  @action findAllProcedimentosOdontologico = async ({ pageNumber, search }) => {
    try {
      const response = await getProcedimentoOdontologico({
        localOdontograma: [],
        pageableDTO: {
          sortDir: "ASC",
          sortField: "codigoTermo",
          pageNumber,
          pageSize: 30,
        },
        search,
      });

      return response;
    } catch (error) {
      this.odontogramaStore.openNotification(
        "Erro ao carregar os procedimentos odontológicos",
        "error"
      );
    }
  };

  getUnidadeLogadaId = () => {
    const unidadeLogada = JSON.parse(localStorage["_immortal|unidadeLogada"]);
    return unidadeLogada?.id ;
  };

  @action findProfissionalSaudeByUnidadeComAgendaPage = async ({
    pageNumber,
    nome,
  }) => {
    try {
      const unidadeId = await this.getUnidadeLogadaId();
      const response = await findProfissionalSaudeByUnidadeComAgendaPage({
        search: nome,
        unidadeId,
        pageableDTO: {
          sortDir: "ASC",
          sortField: "nome",
          pageNumber,
          pageSize: 30,
        },
      });
      return response;
    } catch (error) {
      this.odontogramaStore.openNotification(
        "Erro ao carregar os profissionais",
        "error"
      );
    }
  };

  @action handleFieldModal = (field, value) => {
    this.orcamento[field] = value;
  };

  @action getDadosOrcamentoOdontologico = async (id, sujeitoAtencaoId) => {
    try {
      let procedimentosOdontologicoOrcamento = [];

      const response = await findOrcamentoById(id);

      const orcamentoProcedimentos = response.orcamentoProcedimentos.map(item => {
        const { procedimentoOdontologico } = item;
        return {
          ...item,
          ...procedimentoOdontologico,
          checked: true,
        }
      })

      if(response.tipo === "ODONTOLOGICO") {
        const procedimentosResponse = await findAllProcedimentoOdontologicoSemOrcamento({
          sujeitoAtencaoId: sujeitoAtencaoId || this.sujeitoAtencaoStore.objView.id,
          orcado: false,
          procedimentoRealizado: false,
          pageableDTO: { sortDir: "DESC", sortField: "agendamento.data" }
        });

        procedimentosOdontologicoOrcamento = procedimentosResponse.map(item => {
          return {
            ...item,
            checked: false,
            valorUnitario: "",
          }
        });
      }

      const orcamento = {
        ...response,
        totalParcelas: { value: response.totalParcelas },
        tipoPagamento:
          response.tipoPagamento === "A_VISTA"
            ? tiposModalOrcamento[0]
            : tiposModalOrcamento[1],
        desconto: {
          tipoDesconto:response.desconto.tipoDesconto ? tiposDescontoModalOrcamento[tipoDesconto[response.desconto.tipoDesconto]] : null,
          valorDesconto: String(response.desconto.valorDesconto.toFixed(2) || "").replace('.', ','),
        },
        orcamentoProcedimentos: [...orcamentoProcedimentos, ...procedimentosOdontologicoOrcamento],
      };

      this.orcamento = {...orcamento}
      return {...orcamento}
    } catch(e) {
      this.sujeitoAtencaoStore.openNotification(
        "Erro ao carregar o orcamento",
        "error"
      );
    }
  };

  @action
  findOrcamentoById = async (id) => {
    try {
      const orcamento = await findOrcamentoById(id);

      this.orcamento = { ...orcamento }
      return { ...orcamento };

    } catch (e) {
      console.error("Erro ao buscar orçamento:", e);
      throw new Error(e); 
    }
  };

  @action errorLimiteDesconto = (limiteDescontoOrcamento) => {
    const { desconto, valor, subtotal } = this.orcamento;
    const { valorDesconto, tipoDesconto } = desconto;

    if (tipoDesconto && valorDesconto) {
      const subtotalNumber =
        typeof subtotal === "string"
          ? string.currencyMaskToFloat(subtotal)
          : subtotal;
      const discountValue = valor - subtotalNumber;
      const discountPercentage = (discountValue / valor) * 100;

      return discountPercentage > limiteDescontoOrcamento;
    }
  };

  @action handleFaturarFinanceiroCompleto = async (orcamentoId) => {
      try {
        const linkFaturamentoOrcamento =  await getLinkFaturamentoOrcamento({ orcamentoId });
        window.open(linkFaturamentoOrcamento);
        
      } catch(error) {
        console.log(error);
      }
    }

    @action findAllProcedimentos = async (variables) => {
      try {
        const { pageNumber, nome } = variables;

        const response = await findAllProcedimentos({
          ativo: true,
          nome,
          pageableDTO: {
            sortDir: "ASC",
            sortField: "nome",
            pageNumber,
            pageSize: 30,
          },
        });
  
        return response;
      } catch (error) {
        this.openNotification(
          "Erro ao carregar os procedimentos",
          "error"
        );
      }
    }

  @action getDadosOrcamentoOrtopedico = async (id) => {
    try {
      const response = await findOrcamentoOrtopedicoById(id);
      const orcamentoProcedimentos = response.orcamentoProcedimentos.map(item => {
        return {
          ...item,
          checked: item.espessura === null ? false : true,
        }
      })

      this.orcamento = {
        ...response,
        orcamentoProcedimentos,
      };

    } catch(e) {
      this.sujeitoAtencaoStore.openNotification(
        "Erro ao carregar o orcamento",
        "error"
      );
    }
  };

  @action onChangeTipoRevestimento = async (tipo) => {
    this.tipoRevestimento = tipo
  }
}
